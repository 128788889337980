import { FC, MouseEvent, ReactNode } from 'react'
import './button-styles.css'

export const TEST_ID = 'button-component'

interface ButtonProps {
  children: ReactNode
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
}

const Button: FC<ButtonProps> = ({ children, onClick }) => {
  return (
    <button className="btn" data-testid={TEST_ID} onClick={onClick}>
      {children}
    </button>
  )
}

export default Button
