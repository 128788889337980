import { Button } from './components'

const App = () => {
  return (
    <>
      <h3>Privilexi</h3>
      <p>Some Text, more texts</p>
      <Button>Button</Button>
    </>
  )
}

export default App
