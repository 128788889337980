// https://www.reddit.com/r/reactjs/comments/iyehol/import_react_from_react_will_go_away_in_distant/
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

import './styles/index.css'

const container = document.getElementById('root')

const root = createRoot(container!)
root.render(
  <StrictMode>
    <App />
  </StrictMode>
)

serviceWorkerRegistration.register()
